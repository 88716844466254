var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("popover-timestamp", { attrs: { deviceId: _vm.deviceId } }),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("popover-main-value", { attrs: { aqi: _vm.currentReading.aqi } }),
          _c("v-spacer"),
          _c("popover-averages", {
            ref: "averages",
            attrs: { deviceId: _vm.deviceId },
          }),
        ],
        1
      ),
      _c("popover-percent-changed", {
        ref: "percentChanged",
        attrs: { deviceId: _vm.deviceId },
      }),
      _c("popover-aqi-description", { attrs: { aqi: _vm.currentReading.aqi } }),
      _c("popover-measurements", {
        attrs: {
          deviceId: _vm.id,
          permissions: _vm.permissions,
          sensorReadings: _vm.sensorReadings,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }