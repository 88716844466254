var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    [
      _c(
        "v-list-item",
        [
          _c(
            "v-radio-group",
            {
              staticClass: "radio-buttons",
              attrs: { mandatory: false },
              on: {
                change: function ($event) {
                  return _vm.parameterChange()
                },
              },
              model: {
                value: _vm.selectedParameter,
                callback: function ($$v) {
                  _vm.selectedParameter = $$v
                },
                expression: "selectedParameter",
              },
            },
            _vm._l(_vm.parameterOptions, function (item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", disabled: !item.disabled },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "div",
                                  _vm._g(_vm._b({}, "div", attrs, false), on),
                                  [
                                    _c("v-radio", {
                                      key: index,
                                      attrs: {
                                        disabled: item.disabled,
                                        value: item.value,
                                        label: item.label,
                                        color: "primary",
                                        "data-cy":
                                          "measurementParameter" + index,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("accountSubscriptions.upgradeData"))
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }