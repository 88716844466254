import { render, staticRenderFns } from "./popoverTitle.vue?vue&type=template&id=6b4aaaba&scoped=true&"
import script from "./popoverTitle.vue?vue&type=script&lang=js&"
export * from "./popoverTitle.vue?vue&type=script&lang=js&"
import style0 from "./popoverTitle.vue?vue&type=style&index=0&id=6b4aaaba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b4aaaba",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/buildp/jenkins/workspace/bs-ui-pipeline/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b4aaaba')) {
      api.createRecord('6b4aaaba', component.options)
    } else {
      api.reload('6b4aaaba', component.options)
    }
    module.hot.accept("./popoverTitle.vue?vue&type=template&id=6b4aaaba&scoped=true&", function () {
      api.rerender('6b4aaaba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/devicePopover/title/popoverTitle.vue"
export default component.exports