var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-widget",
    {
      staticClass: "history-widget",
      attrs: {
        deviceIds: _vm.deviceIds,
        widgetId: _vm.widgetId,
        widgetType: _vm.widgetType,
        widgetMaxDevices: _vm.widgetMaxDevices,
        title: _vm.name,
        subtitle: _vm.deviceType,
        fullscreen: true,
        observeSize: true,
        "data-cy": "HistoryWidget",
      },
      on: {
        fullscreenChange: _vm.fullscreenChange,
        removeDevice: _vm.removeDevice,
        removeWidget: _vm.removeWidget,
        widgetSizeChanged: _vm.updateWidgetSize,
        refreshDashboard: _vm.refreshDashboard,
      },
    },
    [
      _c("history-menu-items", {
        attrs: {
          slot: "actions",
          selectedDevices: _vm.graph.selectedDevices,
          passedinParameter: _vm.graph.selectedParameter,
          permissions: _vm.permissions,
        },
        on: {
          "update:passedinParameter": function ($event) {
            return _vm.$set(_vm.graph, "selectedParameter", $event)
          },
          "update:passedin-parameter": function ($event) {
            return _vm.$set(_vm.graph, "selectedParameter", $event)
          },
          parameterChange: _vm.parameterChange,
        },
        slot: "actions",
      }),
      _c("history-graph", {
        ref: "historyGraph",
        staticClass: "history-widget-content",
        attrs: {
          slot: "content",
          open: _vm.graph.isOpen,
          allowMonth: false,
          allowWeek: false,
          allowThreeMonths: false,
          isFullscreen: _vm.graph.isFullscreen,
          isDetachedDialog: _vm.graph.isDetachedDialog,
          removableDevices: _vm.graph.removableDevices,
          selectedDevices: _vm.graph.selectedDevices,
          selectedParameter: _vm.graph.selectedParameter,
          selectedTimePeriod: _vm.graph.timePeriod,
          width: _vm.dimensions.width,
          height: _vm.dimensions.height,
          permissions: _vm.permissions,
        },
        on: {
          graphPeriodChanged: _vm.updateTimePeriod,
          removeDevice: _vm.removeDevice,
        },
        slot: "content",
      }),
      _c(
        "div",
        {
          staticClass: "toolbar",
          attrs: { slot: "left-toolbar" },
          slot: "left-toolbar",
        },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", "data-cy": "refreshHistory" },
              on: { click: _vm.refresh },
            },
            [
              _c("v-icon", { attrs: { color: "grey" } }, [
                _vm._v("mdi-refresh"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }