var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-subtitle",
    {
      staticClass: "small-subtitle",
      attrs: { "data-cy": "currentCalibrationFactor" },
    },
    [
      _c("span", [
        _vm._v(
          " " + _vm._s(_vm.$t("map.popover.currentCalibrationFactor")) + ": "
        ),
      ]),
      _c("span", { staticClass: "value" }, [
        _vm._v(" " + _vm._s(_vm.currentCalibrationFactor) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }